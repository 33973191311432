i {
	&.sm-md {
		font-size: 3rem;
	}

	&.xlarge {
		font-size: 8rem;
	}

	&.promo {
		color: $primary-color;
	}
}

.ma-region-title .material-icons {
	font-size: inherit;
	margin-right: 1rem;
}
