$sidenav-height: 44px;

#sidenav-overlay {
  	z-index: 999;
}

.drag-target {
  	z-index: 1000;
}

.side-nav {
    z-index: 1000;
    height: 100%;
    width: $sidenav-width;

	// Right Align
	&.right-aligned {
		right: initial;
		left: initial;
	}

	li > a {
		padding: 0 16px;
	}

	.collapsible {
        .collapsible-body {
            background-color: $sidenav-bg-color;
            padding: 0;
        }
	}
}

/*
Sidebar Fixed
*/
.side-nav-fixed {
    .side-nav {
        @extend .fixed;
    }

	@media #{$large-and-up} {
        #app-sidenav-trigger {
            display: none!important;
        }

        .top-nav-wrapper {
            z-index: 1001;
        }

		.side-nav {
            transform: translateX(0)!important;
            top: $navbar-height;
            height: calc(100% - #{$navbar-height});
		}

    	main, footer, nav.nav-breadcrumbs {
        	padding-left: $sidenav-width;
      	}

        .container, &.main-container .ma-main-container {
            width: 85%;
        }
	}

  	@media #{$medium-and-down} {
        #app-sidenav-trigger {
            display: block!important;
        }

    	main, footer {
      		padding-left: 0;
    	}
  	}
}

/* SideNav Header */
.userView {
    @include hide();

    a, span {
        color: $sidenav-header-text-color;
    }

    a {
        padding: 0;
        line-height: 24px;
        height: auto;
    }
}

.side-nav .userView {
    @include showBlock();
}
