// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;
$spacers: (
  0: (
    x:   0,
    y:   0
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  )
) !default;
$border-width:               1px !default;

// Margin and Padding

.m-x-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}-t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}-r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}-b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}-l-#{$size} { #{$prop}-left:   $length-x !important; }

    // Axes
    .#{$abbrev}-x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}-y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}
