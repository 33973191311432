.footer-copyright {
	.version
	, .customize
	, .screenreader {
		display: inline-block;
	}

	a {
		color: inherit;
	}
}
