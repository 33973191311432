// Icons
.icon-float-left i {
	@extend .left;
}

.icon-float-right i {
	@extend .right;
}

button:not(.btn-floating), a:not(.btn-floating) {
	&.icon-float-right, &.icon-float-left {
		i {
			margin-right: 0;
			margin-left: 0;
		}
	}

	&.icon-float-left .ma-button-label ~ i {
		margin-right: 15px;
	}

	&.icon-float-right .ma-button-label ~ i {
		margin-left: 15px;
	}

	&.no-label {
		padding: 0 1rem;
	}
}

.ma-button.btn-floating .ma-button-label {
	@include hide();
}

// Fixed Action Button
.fab-relative {
	position: relative;
}

.fixed-action-btn {
	bottom: 45px;
	z-index: 996;

	&.fab-left {
		left: 25px;
	}

	&.fab-right {
		right: 25px;
	}

	&.fab-absolute {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&.horizontal ul {
		bottom: 80px;

		li {
			margin: 5px 0 0;
		}

		.btn-floating {
			opacity: 0;
			margin: 5px 10px;
		}
	}

	/* Toolbar */
	&.toolbar {
		&.active {
			& > a i {
				opacity: 0;
			}
		}
		padding: 0;
		height: $button-floating-large-size;

		ul {
			display: flex;
			top: 0;
			bottom: 0;

			li {
				flex: 1;
				display: inline-block;
				margin: 0;
				height: 100%;
				transition: none;

				button {
					display: block;
					overflow: hidden;
					position: relative;
					width: 100%;
					height: 100%;
					background-color: transparent;
					box-shadow: none;
					color: #fff;
					line-height: $button-floating-large-size;
					z-index: 1;

					i {
						line-height: inherit;
					}
				}
			}
		}
	}
}

/* Inline button
This is to give space as if there was a label */
.ma-btn-inline {
	margin-top: 2rem;
}

// IG and IRR buttons
.a-Button, .ui-button {
	&:focus {
		outline: none;
		background-color: transparent;
	}

    background-color: transparent;
    box-shadow: none;
}

// Button block
.ma-btn-block {
	display: block;
	width: 100%;
}
