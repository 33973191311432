.collection:not(.with-header) .collection-header {
	@include hide();
}

.collection span.badge {
	max-width: 60%;
	display: inline-block;
}

.collection .secondary-content:empty {
	@include hide();
}
