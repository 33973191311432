/*!
 * material-apex - Material Design Theme for Oracle APEX 5
 * @author Vincent Morneau
 * @version v0.11.0
 * @link 
 * @license MIT
 */
@charset "UTF-8";

// Required files for overwrites
// @import "components/prefixer";
@import "components/mixins";
@import "components/color";

// Custom APEX variables
@import "apex_variables";
@import "apex_mixins";

// Original materialized CSS files
@import "components/normalize";
@import "components/global";
@import "components/badges";
@import "components/icons-material-design";
@import "components/grid";
@import "components/navbar";
@import "components/roboto";
@import "components/typography";
@import "components/transitions";
@import "components/cards";
@import "components/toast";
@import "components/tabs";
@import "components/tooltip";
@import "components/buttons";
@import "components/dropdown";
@import "components/waves";
@import "components/modal";
@import "components/collapsible";
@import "components/chips";
@import "components/materialbox";
@import "components/forms/forms";
@import "components/table_of_contents";
@import "components/sideNav";
@import "components/preloader";
@import "components/slider";
@import "components/carousel";
@import "components/tapTarget";
@import "components/pulse";
@import "components/date_picker/default";
@import "components/date_picker/default.date";
@import "components/date_picker/default.time";

// Custom APEX styles
@import "apex_alert";
@import "apex_buttons";
@import "apex_global";
@import "apex_cards";
@import "apex_carousel";
@import "apex_code";
@import "apex_collapsible";
@import "apex_collections";
@import "apex_datepicker";
@import "apex_demo";
@import "apex_dropdown";
@import "apex_forms";
@import "apex_footer";
@import "apex_grid";
@import "apex_icons";
@import "apex_ig";
@import "apex_ir";
@import "apex_materialbox";
@import "apex_media";
@import "apex_modal";
@import "apex_nav";
@import "apex_pagination";
@import "apex_preloader";
@import "apex_promo";
@import "apex_sidenav";
@import "apex_table";
@import "apex_tabs";
@import "apex_timeline";
@import "apex_themeroller";
@import "apex_toasts";
@import "apex_toolbar";
@import "apex_typography";
@import "apex_spacers";
@import "apex_wizard";
