/*
Colors
*/
.ma-colored-field,
.ma-colored-region {
	label {
		// we are enforcing this color so it should overwrite the :focus value
		color: inherit!important;
	}

	input {
		// we are enforcing this color so it should overwrite the :focus value
		border-color: inherit!important;
		box-shadow: inherit!important;
	}
}
/*
Date Pickers
*/
.datepicker,
.u-TF-item--datepicker {
	cursor: pointer;
}
/*
Inputs
*/
.input-field {
	margin-top: 1.5rem;

	label {
		left: 0;

		&:not(.label-icon).active {
			transform: translateY(-190%);
		}
	}
}

.item-image-responsive img {
	@extend .responsive-img;
}

.item-image-circle img {
	@extend .circle;
}

.item-fade-in img {
	@extend .fade-in;
}

fieldset,
fieldset.textarea {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
}

fieldset.checkbox_group,
fieldset.radio_group {
	margin-bottom: 20px;
}

:focus {
	outline: none;
}
/*
Autocomplete
*/
div.ac_results {
	border: none;
	overflow: visible;

	ul {
		@extend .autocomplete-content;
		@extend .dropdown-content;

		li {
			font-size: 16px;
			color: $primary-color;
			display: block;
			line-height: 22px;
			padding: 14px 16px;

			&.ac_odd {
				background-color: initial;
			}

			&.ac_over {
				background-color: #eee;
			}

			strong {
				font-weight: 500;
				color: #444;
			}
		}
	}
}
/* Popup LOV */
.ma-popuplov-modal {
	.collection {
		br {
			@include hide();
		}

		a {
			@extend .collection-item;
		}
	}
}

.ma-popuplov {
	fieldset {
		display: block;
	}

	.lov input {
		border-bottom: 1px solid $input-border-color;
	}

	label {
		transform: translateY(-190%);
		font-size: 0.8rem;
	}

	.prefix ~ fieldset {
		margin-left: 3rem;
	}

	.ma-chevron-up:before {
		@include apex5-font();
		content: "\e012";
	}
}
/* Validation */
span.ma-error-message {
	color: $error-color;
	font-size: 0.8rem;
	position: absolute;
	top: 0;
}

.input-field {
	// br {
	// 	display: none;
	// }

	span.ma-error-message {
		top: 3.5rem;
	}
}

.input-field .prefix ~ label {
	width: auto;
}
/* Display Only */
.input-field .prefix ~ .display_only {
	margin-left: 3rem;
}
/* No Label */
.item-no-label > label {
	visibility: hidden!important;
}
/* Switches */
.switch label {
	position: relative;
}

.input-field .switch + label {
	font-size: 0.8rem;
	transform: translateY(-190%);
}
/* Ranges */
.input-field .range-field + label {
	font-size: 0.8rem;
	transform: translateY(-190%);
}

input[type=range] {
	border: none;
}
/* Text Alignment */
.ma-field-container {
	&.center-align,
	&.left-align,
	&.right-align {
		input:not(.select-dropdown),
		textarea {
			text-align: inherit;
		}
	}
}
/* Fieldset Labels */
.label-block {
	display: block;
}
// Textarea
.character-counter {
	float: right;
	font-size: 12px;
}
// Shuttle
fieldset.shuttle {
	width: 100%;
}

fieldset.shuttle table.shuttle {
	width: 100%;
	border-collapse: collapse;
}

fieldset.shuttle td {
	vertical-align: top;
}

fieldset.shuttle .shuttleControl,
fieldset.shuttle .shuttleSort2 {
	width: 1%;
}

fieldset.shuttle .shuttleSelect1,
fieldset.shuttle .shuttleSelect2 {
	width: 49%;
}

fieldset.shuttle select.shuttle_left,
fieldset.shuttle select.shuttle_right {
	width: 100%;
	min-height: 14.0rem;
	margin: 0.4rem 0;
	display: block;
}
