.icon-container {
	.icon-preview {
		height: 120px;
		text-align: center;
	}

  	i {
		font-size: 3em;
		display: block;
		margin-bottom: 10px;
	}
}

.dynamic-color {
	.red, .pink, .purple, .deep-purple, .indigo, .blue, .light-blue, .cyan, .teal, .green, .light-green, .lime, .yellow, .amber, .orange, .deep-orange, .brown, .grey, .blue-grey, .black, .white, .transparent {
		height: 55px;
		width: 100%;
		padding: 0 15px;
		line-height: 55px;
		font-weight: 500;
		font-size: 12px;
		display: block;
		box-sizing: border-box;
	}

	.darken-1, .darken-2, .darken-3, .darken-4 {
		color: #fff;
	}
}

.icon-demo {
  	line-height: 50px;
}

.promo-i i {
	color: $primary-color;
}