// temporary fix for search bar
.nav-wrapper, .nav-wrapper form, .nav-wrapper form .input-field{ height: 100%; }
nav .search-nav-wrapper .input-field label.active {
    transform: translateY(0);
}
// end temporary fix for search bar

.page-navbar-fixed .top-nav-wrapper {
    @extend .navbar-fixed;
}

nav ul a {
    display: inline-block;
}

nav .nav-wrapper i {
	font-size: 2rem!important;
}

@media #{$medium-and-up} {
  	nav .nav-wrapper i {
		font-size: 2.7rem!important;
	}
}

.drag-target {
	top:$navbar-height-mobile;
}

@media #{$medium-and-up} {
  	.drag-target {
		top:$navbar-height;
	}
}

.page-center-logo .brand-logo {
	@extend .center;
}

.navbar-list .dropdown-content {
    li, a, i, span {
        height: $navbar-height-mobile;
        line-height: ($navbar-height-mobile / 2);
    }
}

@media #{$medium-and-up} {
	.navbar-list .dropdown-content {
		li, a, i, span {
			height: $navbar-height;
			line-height: ($navbar-height / 2);
		}
	}
}

/* Scrollspy */
.apex-rds {
    @extend .table-of-contents;
}

/* Region Display Selector */
.apex-rds-selected a {
    font-weight: 500;
    padding-left: 18px;
    border-left: 2px solid $secondary-color;
}

/* Button */
nav .button-collapse {
    margin: 0;
}
