@mixin hide() {
	display:none!important;
	visibility: hidden!important;
}

@mixin showBlock() {
	display:block!important;
	visibility:visible!important;
}

@mixin showInlineBlock() {
	display:inline-block!important;
	visibility:visible!important;
}

@mixin browserDefaultInput() {
	background-color: transparent;
	border: none;
	border-bottom: none;
	border-radius: 0;
	outline: none;
	height: auto;
	width: auto;
	font-size: inherit;
	margin: 0;
	padding: 0;
	box-shadow: none;
	box-sizing: content-box;
	transition: none;
}

@mixin browserDefaultSelect() {
	display:block;
	width: 100%;
	height: $input-height;
	border-bottom: $input-border;
}

@mixin vh {
	border: 0!important;
	clip: rect(0 0 0 0)!important;
	height: 1px!important;
	margin: -1px!important;
	overflow: hidden!important;
	padding: 0!important;
	position: absolute!important;
	width: 1px!important;
}

@mixin ma-s2 {
	width: 16.66667%;
}

@mixin ma-s3 {
	width: 25%;
}

@mixin ma-s4 {
	width: 33.3333%;
}

@mixin ma-s6 {
	width: 50%;
}

@mixin ma-s9 {
	width: 75%;
}

@mixin ma-s10 {
	width: 83.33333%;
}

@mixin ma-s12 {
	width: 100%;
}

@mixin apex5-font {
	font-size: 16px;
	font-family: apex-5-icon-font!important;
	font-style: normal!important;
	font-weight: 400!important;
	font-variant: normal!important;
	text-transform: none!important;
	speak: none;
	line-height: 1;
}

@mixin material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

@mixin small-btn {
	height: 24px;
	line-height: 18px;
	padding: 0 1rem;
}

@mixin btn {
	@extend .btn;
}

@mixin btn-flat {
	@extend .btn-flat;
}

@mixin ma-card {
	@extend .card;
}

@mixin z-depth-1 {
	@extend .z-depth-1;
}

@mixin z-depth-2 {
	@extend .z-depth-2;
}

@mixin z-depth-3 {
	@extend .z-depth-3;
}

@mixin z-depth-4 {
	@extend .z-depth-4;
}

@mixin z-depth-5 {
	@extend .z-depth-5;
}

// Flexbox regions
@mixin ma-flex {
	display: flex;

	&.ma-flex-justify {
		justify-content: space-between;
	}

	& > .ma-flex-left, & > .ma-flex-right {
		align-self: center;

		&:empty {
			@include hide();
		}
	}

	& > .ma-flex-left {
		text-align: left;
		margin-right: 1rem;
	}

	& > .ma-flex-right {
		text-align: right;
		margin-left: 1rem;
	}

	& > .ma-flex-center {
		text-align: center;
	}

	& > .ma-flex-content {
		width: 100%;
		flex-shrink: 99999;
	}
}
