html {
	// !important is required to overwrite what APEX already does
	// Will be set to visible when the page has finished loading
	// using JavaScript
	visibility: hidden!important;
}

body {
	background: $background-color;
}

form p {
	text-align: inherit;
}

ul {
	margin: 0;

	&.table-of-contents {
		margin-top: 0;
		padding-top: 48px;
	}
}

// APEX hidden components
input.u-vh {
	@include vh();
}

// Main Container Handler
.main-container .ma-main-container {
	@extend .container;
	margin-top: 1rem;
}

.t-Dialog-bodyWrapperOut {
	margin-top: 1rem;
}

// Fix for menu icons
i {
	&.left-l {
		float: left;
		margin-right: 0;

		@media #{$large-and-up} {
			margin-right: 15px;
		}
	}

	&.right-l {
		float: right;
		margin-left: 0;

		@media #{$large-and-up} {
			margin-left: 15px;
		}
	}
}

// Hamburger Menu
#app-sidenav-trigger {
	display: block!important;

	&.hide {
		display: none!important;
	}
}

// Materialbox
.materialbox-caption {
	top:0;
}

// Sticky Footer
body > form {
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	main {
		flex: 1 0 auto;
	}
}

// Alignments
.ma-region-title-left .ma-region-title
, .ma-region-body-left .ma-region-body
, [align='left'] {
	text-align: left;
}
.ma-region-title-center .ma-region-title
, .ma-region-body-center .ma-region-body
, [align='center'] {
	text-align: center;
}
.ma-region-title-right .ma-region-title
, .ma-region-body-right .ma-region-body
, [align='right'] {
	text-align: right;
}

// Badges
span.badge.new:after {
	content: "";
}

// Parallax
.parallax-container {
	height: initial;
	min-height: 400px;
}

// Blockquote
blockquote {
	padding: 1rem;
}

// Error template
.a-Error-details h2
, h2.a-Notification-title
, .a-Notification-details h2 {
	font-size: $h5-fontsize;
	margin-top: 0;
}

// Notifications
body .ui-dialog.ui-widget .ui-dialog-content.ui-widget-content.a-Notification-details {
	padding: 1rem;
}

// Apply flex to regions
.ma-region-standard, .ma-region-card .card-content, .ma-region-header, .ma-region-buttons {
	@include ma-flex();
}

.ma-region-buttons {
	.ma-flex-left
	, .ma-flex-center
	, .ma-flex-right {
		white-space: nowrap;
	}

	&:not(.card-action) {
		.ma-flex-left
		, .ma-flex-center
		, .ma-flex-right {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
}
